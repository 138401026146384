import React, { Fragment } from 'react'
import { navigate } from 'gatsby'
import { Box, Spinner, Text } from 'theme-ui'
import CanClubCustomizeStep from '~/components/CanClub/Step/Customize'
import { useCanClub } from '~/hooks/components/use-can-club'
import { getClubUpdatedInstallmentLineItems } from '~/utils/subscriptions'

const CanClubCustomizeInstallmentPage = ({
  error,
  subscription,
  updateSubscription,
}) => {
  const {
    customizationError,
    customizationIsSaving,
    customizationVariantsIds,
    products,
    setCustomizationError,
    setCustomizationIsSaving,
    setCustomizationShowError,
  } = useCanClub()

  const handleContinue = async () => {
    if (customizationIsSaving) return
    if (customizationError) {
      setCustomizationShowError(true)
      return
    }

    setCustomizationIsSaving(true)
    setCustomizationShowError(false)

    try {
      const newLineItems = getClubUpdatedInstallmentLineItems(
        subscription,
        products,
        customizationVariantsIds
      )
      await updateSubscription({ lineItemsAttributes: newLineItems })
      setCustomizationIsSaving(false)
      navigate('/account/club/')
    } catch (error) {
      setCustomizationError(
        'Something went wrong. Please try again or refresh the page.'
      )
      setCustomizationIsSaving(false)
      setCustomizationShowError(true)
    }
  }

  return (
    <Fragment>
      {!subscription && !error && (
        <Box py={10} sx={{ textAlign: 'center' }}>
          <Spinner
            color="brandTertiary"
            size="100"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      )}
      {subscription && !error && (
        <CanClubCustomizeStep
          continueLabel={'Save your selections'}
          handleContinue={() => handleContinue()}
        />
      )}
      {error && (
        <Box py={10} sx={{ textAlign: 'center' }}>
          <Text>{'Something went wrong. Please try again.'}</Text>
        </Box>
      )}
    </Fragment>
  )
}

export default CanClubCustomizeInstallmentPage
