import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import PrivateRoute from '~/components/Auth/PrivateRoute'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CanClubInstallmentPage from '~/components/CanClub/Page/Installment'
import { useCanClub } from '~/hooks/components/use-can-club'
import {
  getClubInstallmentDefaultItems,
  getSubscriptionContentFromTags,
} from '~/utils/subscriptions'

// Editing an existing subscription's installment.
const CanClubInstallment = ({ data, subscriptionId }) => {
  const {
    customizationVariantsIds,
    customizationVariantsIsFetching,
    loadCustomizationVariantsBySkus,
    products,
    productsIsSet,
    setCustomizationSubscriptionId,
    setProducts,
    setQuantity,
  } = useCanClub()
  const { error, subscription, updateSubscription } = useSubscription({
    subscriptionId,
  })

  const {
    allProductContentData: { nodes: allProductContent },
    allSubscriptionContentData: { nodes: allSubscriptionContent },
  } = data
  const subscriptionContent = getSubscriptionContentFromTags(
    subscription,
    allSubscriptionContent
  )
  const { defaultQuantity } = subscriptionContent || {}

  useEffect(() => {
    if (productsIsSet || !subscriptionContent) return

    const defaultItems = getClubInstallmentDefaultItems(
      subscription,
      subscriptionContent,
      allProductContent
    )

    setProducts(defaultItems)
    setCustomizationSubscriptionId(parseInt(subscriptionId))
  }, [
    allProductContent,
    productsIsSet,
    setCustomizationSubscriptionId,
    setProducts,
    subscription,
    subscriptionContent,
    subscriptionId,
  ])

  // Fetch the variant IDs needed to update the subscription line items.
  useEffect(() => {
    if (
      !productsIsSet ||
      customizationVariantsIsFetching ||
      customizationVariantsIds
    )
      return

    loadCustomizationVariantsBySkus(Object.keys(products))
  }, [
    customizationVariantsIds,
    customizationVariantsIsFetching,
    loadCustomizationVariantsBySkus,
    products,
    productsIsSet,
  ])

  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity, setQuantity])

  return (
    <Layout
      tunneled={true}
      handleTunneledBack={() => navigate('/account/club/')}
    >
      <Metadata noindex={true} />
      <Router basepath="/account">
        <PrivateRoute
          path={`/club/customize/${subscriptionId}/`}
          component={CanClubInstallmentPage}
          error={error}
          subscription={subscription}
          updateSubscription={updateSubscription}
        />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query CanClubInstallment {
    allSubscriptionContentData: allContentfulSubscription {
      nodes {
        ...SubscriptionFragment
      }
    }
    allProductContentData: allContentfulProduct {
      nodes {
        ...ProductClubFragment
      }
    }
  }
`

export default CanClubInstallment
